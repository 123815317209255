<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Pencarian </h4>
            <!-- <a href="/#/login" class="float-right btn btn-success ">Login</a> -->

          </slot>
        </CCardHeader>
        <CCardBody>

        <CRow>  
              <CCol sm="2">
              <select class="form-control" v-model='kabkota'>
                <option v-for='opt in kelurahans' v-bind:value="opt">{{opt}}</option>
              </select>
              </CCol> 

              <CCol sm="2">
              <CInput
                v-model="nama_barang"           
                placeholder="Cari Via Nama Barang"
              />
              </CCol>          
              <CCol sm="2">
              <CInput
                v-model="instansi"
                placeholder="Cari Via Instansi"
              />  
              </CCol>
              <CCol sm="2">
              <CInput
                v-model="jenis_hak"
                placeholder="Cari Via Jenis Hak"
              />  
              </CCol> 
              <CCol sm="2">
              <CInput
                v-model="letak_lokasi"
                placeholder="Cari Via Alamat"
              />  
              </CCol>
              <CCol sm="2">
              <CInput
                v-model="luas_tertulis"
                placeholder="Cari Via Luas"
              />  
              </CCol>

              <CCol sm='12' class='float-right text-right'>
                <hr>
                <CButton color="secondary" @click="reset()" class='mr-3'>Reset</CButton>
                <CButton color="success" @click="cariData()">Cari Aset</CButton>
              </CCol>
        </CRow>      

            <!-- <CAlert v-if="dataslength==0" show color="danger" class='text-center'>Kosong! Silahkan ketik kolom pencarian.</CAlert> -->

            <CSpinner v-show="mySpinner" color="info" style="position:fixed;left: 50%;top:50%;z-index: 1000;"/>

            <CModal
              :show.sync="filenull"
              :no-close-on-backdrop="true"
              :centered="true"
              title="Warning"
              color="danger"
            >
              File tidak tersedia / belum diupload.
              <template #header>
                <h6 class="modal-title">Custom smaller modal title</h6>
                <CButtonClose @click="filenull = false" class="text-white"/>
              </template>
              <template #footer>
                <CButton @click="filenull = false" color="success">Ok</CButton>
              </template>
            </CModal>

        </CCardBody>
      </CCard>

        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data Aset Belum Bersertifikat</h4> 
            <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-left form-control">
              <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
            </select>
            <a href="/#/asetunregis/add" class="float-right btn btn-success form-control-sm">Tambah Aset</a>

          </slot>
        </CCardHeader>
        <CCardBody>

        <table class="table table-striped" id="datatable">
          <thead class="bg-info">
            <tr>
              <th>No </th>
              <th>Nama Barang </th>
              <th>Kota / Kabupaten </th>
              <th>Nama Instansi</th>
              <th>Alamat</th>
              <!-- <th style="width:10%">No HAK </th> -->
              <th style="width:10%">Tipe HAK</th>
              <th>Tanggal Perolehan </th>
              <th>Harga Perolehan</th>
              <th>Luas (m2)</th>
              <th>Aksi </th>
            </tr>
          </thead>
          <tbody >
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
  </CRow>
  </div>
</template>

<style scoped>
.hiddenRow{
  padding: 0 !important;
}
</style>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import 'datatables.net-buttons'
import axios from 'axios';
import $ from 'jquery'; 
import { LMap, LTileLayer, LPolygon, LMarker } from 'vue2-leaflet';
var dttable;

export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    this.loadData();
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon
  },
  data: function() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
            zoom: 13,
            center: [-0.0353948,109.2615099],
            gridColumns: ['No', 'Nomor Berkas', 'Tahun','Jenis Hak', 'Nomor Hak','Kegiatan','Atas Nama', 'Rak','Tgl'],
            visible : false,
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Nomor Berkas', value: 'nomor_berkas' },
              { text: 'Tahun', value: 'tahun_arsip' },
              { text: 'Jenis Hak', value: 'jenis_hak' },
              { text: 'Nomor Hak', value: 'nomor_hak' },
              { text: 'Kegiatan', value: 'kegiatan' },
              { text: 'Atas Nama', value: 'atas_nama' },
              { text: 'Rak', value: 'rak' },
              { text: 'Tgl - Tgl Kembali', value: 'tgl' },
            ],
            coordinates:[],
            dataslength : 0,
            instansi:"",
            opened: [],
            su:"",
            nama_peminjam:"",
            nib:"",
            jenis_hak:"",
            nama_barang:"",
            luas_tertulis:"",
            letak_lokasi:"",
            mySpinner : false,
            filenull : false,
            kabkota:"PILIH KOTA/KAB",
            kelurahans:['PILIH KOTA/KAB','PONTIANAK','SINGKAWANG','BENGKAYANG','MEMPAWAH','MELAWI','KAYONG UTARA','KAPUAS HULU','KETAPANG','KUBU RAYA','LANDAK','SAMBAS','SANGGAU','SEKADAU','SINTANG'],
            // kelurahans:['PILIH KEL/DESA','AKCAYA','BANGKA BELITUNG','PARIT TOKAYA','BATU LAYANG','SIANTAN HULU'],
            products:[],
            options:['Pinjam','Print','Copy'],
            items:[],
            datas:[],
            datasbackup:[],
            datascari:[],
            toasts: [],
            pencarians:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    toggle(id, koor, prop) {
      // const index = this.opened.indexOf(id);
      // if (index > -1) {
      //   this.opened.splice(index, 1)
      // } else {
      //   this.coordinates = JSON.parse(koor);
      //   this.opened.push(id);
      //   // document.getElementById('map_'+koor)
      // }
      this.$store.commit('setProp', prop);
      this.$store.commit('setCoord', koor);
      this.$router.push("/asetunregis/detail/"+id);
    },
    cariData(){
      this.mySpinner = true;
      dttable.draw();

      this.mySpinner = false;
    },
    loadData: function() {

      this.mySpinner = true;
      const cari = { 
              kabkota: this.kabkota, 
              nomor_hak: this.nomor_hak,
              su: this.su,
              nib: this.nib,
              };
      axios.get("http://139.180.219.157/intip/")
        .then((response) => {
            
             this.datas = response.data.features;
             this.datasbackup = response.data.features;
             // alert(JSON.stringify(this.datas));
             this.dataslength = response.data.length;
            this.mySpinner = false;
        })      

      if (this.tahun == null){
        dttable = $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":process.env.VUE_APP_BASE_URL+"asetbelumbersertifikat",
              "dataSrc": "features",
              "columns": [
                  { "data": "no", class:"text-center"  },
                  { "data": "nama_barang"},
                  { "data": "kabupaten_kota", class:"text-center"  },
                  { "data": "nama_instansi" , class:"text-center" },
                  { "data": "letak_lokasi", class:"text-center" },
                  // { "data": "no_hak", class:"text-center" },
                  { "data": "jenis_hak", class:"text-center" },
                  { "data": "tgl_perolehan", class:"text-center" },
                  { "data": "nilai_perolehan", class:"text-center" },
                  { "data": "luas_tertulis", class:"text-center" },
                  { "data": null,
                    render: function(data, type, row, meta)
                      {
                          return "<a href=''>Detail</a>";
                      },
                  },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
        var dat = this;
        dttable =  $('#datatable').DataTable({
          "processing": true,
              "ordering": false,
              "serverSide": true,
              "pageLength": 50,
              "searching":false,
              'serverMethod': 'post',
              "ajax":{
                       "url": process.env.VUE_APP_BASE_URL+"asetbelumbersertifikat",
                       "dataType": "json",
                       "data": function(data){

                            // Append to data
                            data.searchByKotaKab = dat.kabkota;
                            data.searchByNama = dat.nama_barang;
                            data.searchByJenis = dat.jenis_hak;
                            data.searchByInstansi = dat.instansi;
                            data.searchByAlamat = dat.letak_lokasi;
                            data.searchByLuas = dat.luas_tertulis;
                        }
                     },
              "columns": [
                  { "data": "no", class:"text-center"  },
                  { "data": "nama_barang"},
                  { "data": "kabupaten_kota", class:"text-center"  },
                  { "data": "nama_instansi" , class:"text-center" },
                  { "data": "letak_lokasi", class:"text-center" },
                  // { "data": "no_hak", class:"text-center" },
                  { "data": "jenis_hak", class:"text-center" },
                  { "data": "tgl_perolehan", class:"text-center" },
                  { "data": "nilai_perolehan", class:"text-center" },
                  { "data": "luas_tertulis", class:"text-center" },
                  { "data": "detail", class:"text-center" },
                  // { "data": null,
                  //   render: function(data, type, row, meta)
                  //     {
                  //         return "<a href=''>Detail</a>";
                  //     },
                  // },
              ]        
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>